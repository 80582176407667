<template>
	<Header></Header>
	<div class="Order">
		<div class="btn">
			<el-button type="">订单</el-button>
		</div>
		<div class="name"> 
			藏品
		</div>
		<div class="content">
			<div class="box">
				<div class="boxLeft">
					<el-image
						style="width:100%;height:100%;"
					    :src="obj.pnImage"
					    :preview-src-list="srcList"
					    :initial-index="4"
					    fit="cover"
						v-if="showType=='0'"
					/>
					<video
						style="width:100%;height:600px;object-fit: fill;"
						autoplay="" 
						controls="" 
						controlslist="nodownload" 
						loop="" 
						preload="auto" 
						:src="obj.pnFile" 
						v-if="showType=='1'"
					>
						您的浏览器不支持 video 标签。
					</video>
					<audio  
						autoplay=""
						controls=""
						style="width:100%;height:600px;"
						:src="obj.pnFile" 
						v-if="showType=='1'"
					>
						您的浏览器不支持 audio 标签。
					</audio>
					<div class="play" v-if="type !='img'" @click="playBtn">
						<img 
							style="width:50px;height:50px;" 
							v-if="play == false" 
							src="../../../assets/img/play.png"
						>
						<img 
							style="width:50px;height:50px;" 
							v-if="play == true" 
							src="../../../assets/img/isPlay.png"
						>
					</div>
				</div>
				<div class="boxRight">
					<div class="title" style="display: flex;justify-content: space-between;">
						<div>
							{{obj.pnName}}
							<img v-if="obj.pnMatter == '1'" class='shiwu' src="../../../assets/img/shiwu.svg">
						</div>
						<div class="Icon">
							<span style="margin-left:10px;" v-if="pnType=='1'">
								<img :src="IconGrid"/>多版本
							</span>
							<span style="margin-left:10px;" v-if="obj.pnMatter=='1'">
								<img src="../../../assets/img/selected.svg">实物
							</span>
						</div>
					</div>
					<div class="Id">
						Token ID: {{obj.pnTokenId}}   
						<span v-if="obj.pnType=='2'">版号: {{obj.pnNumb}}</span>
					</div>
					<div class="album">{{obj.cnName}}</div>
					<div class="money">
						<div>
							<span>￥</span>{{obj.pnPrice}}
						</div>
						<div class="time" v-if="obj.pnSale != ''">
							出售剩余时间: 
							<span v-html="timeVal"></span>
						</div>
					</div>
					<div class="author">
						<span @click="authorBtn">
							作者：<font>{{obj.pnAuthorName}}</font>
						</span>
						<span @click="ownerBtn">
							&nbsp;&nbsp;持有人：<font>{{obj.pnOwnerName}}</font>
						</span>
					</div>
					<div class='works'>作品简介</div>
					<p class="text">{{obj.pnDes}}</p>
				</div>
			</div>
		</div>
		<div class="name" style="display: flex;justify-content: space-between;margin-top:0px;" v-if="obj.pnMatter=='1'">
			<div>
				收货地址
			</div>
			<div style="color:rgb(74, 133, 189);margin-right:25px;">
				<span @click="drawerAddress = true">更换收货地址</span>&nbsp;&nbsp;&nbsp;&nbsp;
				<span @click="dataBtn('')">新增收货地址</span>
			</div>
		</div>
		<div class="AddressBox" v-if="obj.pnMatter=='1'">
			<div class="Address">
				{{ruleForm.asPerson}} {{ruleForm.peName}} {{ruleForm.cyName}} {{ruleForm.asAddress}} {{ruleForm.isAsMobile}}
			</div>
		</div>
		<div class="name" style="margin-top:0px;">
			支付方式
		</div>
		<div class="paymentBox">
			<div class="payment">
				<div class="momey">
					<div class="momeyBox">
						<div style="height:30px;">
							<el-checkbox v-model="checked" label="账户余额" size="large" @change="changeBox"/>
						</div>
						<div class="wallet">
							<Icon icon="Wallet"></Icon>
						</div>
					</div>
					<div class="text">
						可用余额：<font color=red>￥{{urAmtUsable}}</font>
					</div>
				</div>
				<div class="payPass">
					<span>支付密码：</span>
					<span>
						<el-input type="password" style="width:200px;" v-model="urPayPass" placeholder="支付密码" />
					</span>
				</div>
			</div>
		</div>
		<div class="totalAmount">
			<div>藏品金额:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<font color=red>￥{{obj.pnPrice}}</font></div>
			<div>手续费(<font color=red>{{poundage}}%</font>):&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<font color=red>￥{{poundageVal}}</font></div>
		</div>
		<div class="submit">
			实付款：<font color=red>￥{{amount}}</font>
			&nbsp;&nbsp;&nbsp;&nbsp;<el-button :loading="loading" @click="submitBtn">提交订单</el-button>
		</div>
	</div>
	<Footer></Footer>
	<el-drawer
	    v-model="drawer"
	    :title="title"
	    size="50%"
		:destroy-on-close="true"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
	>
	    <div class="drawerCon" :style="{'height':drawerHeight,'overflow-y':'auto','overflow-x':'hidden'}">
			<EditForm ref="EditForm" @onClose="onClose"></EditForm>
		</div>
		<div class="drawerFooter">
			<el-button @click="drawer = false">取消</el-button>
			<el-button type="primary" @click="saveBtn" :loading="loading">保存</el-button>
		</div>
	</el-drawer>
	<el-dialog
	    v-model="dialogVisible"
	    title="提示"
	    width="30%"
	    :before-close="handleClose"
		:show-close="false"
		:close-on-press-escape="false"
		:close-on-click-modal="false"
	  >
	    确定要以<font style="color:red;font-weight:bold;font-size:16px;">&nbsp;￥{{amount}}&nbsp;</font>的价格购买该藏品吗？
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogVisible = false">取消</el-button>
	        <el-button type="primary" @click="confirmBtn">确定</el-button>
	      </span>
	    </template>
	  </el-dialog>
	  <el-drawer
	      v-model="drawerAddress"
	      title="选择地址"
	      size="30%"
		:destroy-on-close="true"
	  	:show-close="false"
	  	:close-on-press-escape="false"
	  	:close-on-click-modal="false"
	  >
	      <div class="drawerCon" :style="{'height':drawerHeight,'overflow-y':'auto','overflow-x':'hidden'}">
	  		<List ref="List"  @closeAddress="closeAddress"></List>
	  	</div>
	  	<div class="drawerFooter" style="width:30%;">
	  		<el-button @click="drawerAddress = false">取消</el-button>
	  		<el-button type="primary" @click="uploadAddress" :loading="loading">确定</el-button>
	  	</div>
	  </el-drawer>
</template>

<script>
	import Icon from "@/components/Icon"
	import EditForm from './Edit/Edit'
	import List from './Edit/List'
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import IconGrid from "../../../assets/img/grid.svg"
	export default {
		components:{ Icon,EditForm,Header,Footer,List },
		data() {
			return{
				IconGrid:IconGrid,
				drawerHeight:'',
				dialogVisible:false,
				drawerAddress:false,
				drawer:false,
				loading:false,
				checked:true,
				urPayPass:"",
				showType:'0',
				play:false,
				type:null,
				setIntervalTime:null,
				srcList:[],
				pnPropertiesArr:[],
				obj:{},
				countTime:0,
				ptId:'0',
				timeVal:'',
				urAmtUsable:"",
				urFuel:'',
				urBalance:'',
				poundage:'',
				orAddress:'',
				orPerson: '',
				orMobile: '',
				ruleForm:{
					asId:'',
					asPerson:'',
					asMobile:'',
					peId:'',
					peName:'',
					cyId:'',
					cyName:'',
					dtId:'',
					dtName:'',
					asAddress:'',
					asDefault:'1',
					urId:window.localStorage.getItem("urId")
				},
			}
		},
		computed:{
			amount:function () {
				return (Number(this.obj.pnPrice) + (Number(this.poundage)/100 * Number(this.obj.pnPrice))).toFixed(2);
			},
			poundageVal:function () {
				return (Number(this.obj.pnPrice) * (Number(this.poundage)/100)).toFixed(2)
			}
		},
		created(){
			this.getObject();
		},
		methods:{
			authorBtn(){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || urId == null){
					this.$router.push({
						path:'/OthersMain/User/'+ this.obj.pnAuthor +'/'+ this.obj.pnAuthorName,
					})
				}else{
					if(urId == this.obj.urId){
						this.$router.push({
							path:'/Main/User',
						})
					}else{
						this.$router.push({
							path:'/OthersMain/User/'+ this.obj.pnAuthor +'/'+ this.obj.pnAuthorName,
						})
					}
				}
			},
			ownerBtn(){
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || urId == null){
					this.$router.push({
						path:'/OthersMain/User/'+ this.obj.pnOwner +'/'+ this.obj.pnOwnerName,
					})
				}else{
					if(urId == this.obj.urId){
						this.$router.push({
							path:'/Main/User',
						})
					}else{
						this.$router.push({
							path:'/OthersMain/User/'+ this.obj.pnOwner +'/'+ this.obj.pnOwnerName,
						})
					}
				}
			},
			closeAddress(ruleForm){
				this.ruleForm = ruleForm;
				this.drawerAddress = false;
			},
			uploadAddress(){
				this.$refs.List.uploadAddress();
			},
			submitBtn(){
				let that = this;
				if(Number(that.urAmtUsable) < Number(that.amount)){
					that.$message.error("当前可用余额不足，请更换支付方式!");
					return false;
				}
				if(that.ptId == ""){
					that.$message.error("请选择支付方式!");
					return false;
				}
				if(that.ptId == "0"){
					if(that.urPayPass == ""){
						that.$message.error("请输入支付密码!");
						return false;
					}
				}
				if(that.obj.pnMatter == "1"){
					that.orPerson = that.ruleForm.asPerson;
					that.orMobile = that.ruleForm.asMobile;
					that.orAddress = that.ruleForm.peName+','+that.ruleForm.cyName + ',' + that.ruleForm.dtName +','+that.ruleForm.asAddress
				}
				if(that.obj.pnMatter == "1"){
					if(that.ruleForm.asPerson == ""){
						that.$message.error("请选择收货地址!");
						return false;
					}
				}
				if(that.obj.pnType == "1"){
					that.$message.error("当前作品不支持销售!");
					return false;
				}
				that.dialogVisible = true;
			},
			confirmBtn(orAddress){
				let that = this;
				that.dialogVisible = false;
				that.loading = true;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'nft/or/save',
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						ptId: that.ptId,
						urPayPass: that.urPayPass,
						orAddress: that.orAddress,
						orPerson: that.orPerson,
						orMobile: that.orMobile
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg == 2){
						let ptId = res.data.ptId;
						if(ptId == "0"){
							this.$confirm('您已成功购买该藏品,平台将通过区块链将其发送到您的账号下,请注意查看系统消息!', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
								showClose:false,
								showCancelButton:false
							})
							.then(() => {
								that.$router.push({
									path:'/Explore/Collection/'+ that.obj.cnId
								})
							})
							.catch(() => {});
						}
					}else{
						that.$message.error(msg);
					}
					that.loading = false;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			changeBox(val){
				let that =this;
				if(val){
					that.ptId = "0";
				}else{
					that.ptId = "";
				}
			},
			saveBtn(){
				let that = this;
				that.loading = true;
				that.$refs.EditForm.submitForm("ruleForm");
			},
			dataBtn(row){
				let that = this;
				if(row == ""){
					that.title = "新增";
				}else{
					that.title = "修改";
				}
				that.drawer = true;
				that.$nextTick(()=>{
					that.$refs.EditForm.initData(row);
				})
			},
			onClose(index,ruleForm){
				let that = this;
				ruleForm.isAsMobile = ruleForm.asMobile.substring(0,7) +"****";
				if(index = '0'){
					that.ruleForm = ruleForm;
					that.drawer = false;
				}
				that.loading = false;
			},
			getAddress(){
				let that = this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'user/as/getDefault',
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						urId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					let asMobile = res.data.data.asMobile;
					res.data.data.isAsMobile = asMobile.substring(0,7) +"****";
					that.ruleForm = res.data.data;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			procedure(){
				let that = this;
				that.$axios({
					method:'post',
					url: 'sys/py/getService',
					headers: {
						token:window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId
					})
				})
				.then((res)=>{
					that.poundage = res.data.msg;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getMoney(){
				let that = this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'user/ur/getBalance',
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						urId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					that.urAmtUsable = res.data.urAmtUsable;
					that.urFuel = res.data.urFuel;
					that.urBalance = res.data.urBalance;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getObject() {
				let that =this;
				let urId = window.localStorage.getItem("urId");
				if(urId == undefined || urId == null){
					urId = "";
				}
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'nft/pn/getView',
					data:this.$qs.stringify({
						pnId: that.$route.params.pnId,
						urId: urId
					})
				})
				.then((res)=>{
					that.obj = res.data.data;
					that.pnPropertiesArr = JSON.parse(that.obj.pnProperties);
					that.srcList.push(that.obj.pnFile);
					if(that.obj.pnSale != ""){
						that.countTime = that.dateDifference(that.obj.sysTime,that.obj.pnSale);
						that.countDown();
					}
					if(that.obj.pnMatter == "1"){
						that.getAddress();
					}
					that.getMoney();
					that.procedure();
					let suffix = that.obj.pnFileType;
					if(suffix == ".svg" || suffix == ".png" || suffix == ".jpeg" || suffix == ".jpg" || suffix == ".gif"){
						that.type = "img";
					}
					if(suffix == ".mov" || suffix == ".mp4" || suffix == ".ogg" ||suffix == ".gltf" ||suffix == ".glb" ||suffix == ".webm" ||suffix == ".mp4" ||suffix == ".m4v" ||suffix == ".ogv"){
						that.type = "video";
					}
					if(suffix == ".mp3" || suffix == ".wav" || suffix == ".WAV" || suffix == ".OGA" || suffix == ".oga" ){
						that.type = "audio";
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			playBtn(){
				let play = this.play;
				let type = this.type;
				if(play== false){
					this.play = true;
					this.showType = "0";
					if(type == "video"){
						this.showType = "1";
					}
					if(type == "audio"){
						this.showType = "2";
					}
				}else{
					this.play = false;
					this.showType = "0";
				}
			},
			countDown(){
				let that = this;
				that.setIntervalTime = accurateInterval(1000, function() {
				  that.countTime--
				  if(that.countTime <= 0){
					that.setIntervalDownTime.cancel();
					that.setIntervalTime = null;
				  }else{
					that.timeVal = that.days(that.countTime);
				  }
				});
			},
			dateDifference(time1,time2){
				let day11 = time1.replace(/-/g, "/");
				let day22 = time2.replace(/-/g, "/");
				var day_day1 = new Date(day11);
				var day_day2 = new Date(day22);
				let disparity = day_day2.getTime() - day_day1.getTime();
				let second = Math.round(disparity / 1000);
				return second;
			},
			days(second){
				var oldsecond=second,minute=0,hour=0,day=0;
				minute = parseInt(second/60); //算出一共有多少分钟
				second%=60;//算出有多少秒
				if(minute>60) { //如果分钟大于60，计算出小时和分钟
				    hour = parseInt(minute/60);
				    minute%=60;//算出有多分钟
				}
				if(hour>24){//如果小时大于24，计算出天和小时
				    day = parseInt(hour/24);
				    hour%=24;//算出有多分钟
				}
				if(day<=0){
					return  "<font color=red>"+hour+"</font>小时<font color=red>"+minute+"</font>分<font color=red>"+second+"</font>秒";
				}else{
					return  "<font color=red>" + day +  "</font>"+"天<font color=red>"+hour+"</font>小时<font color=red>"+minute+"</font>分<font color=red>"+second+"</font>秒";
				}
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.drawerHeight = h - 147 + 'px';
			}
		},
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			let that = this;
			window.addEventListener('resize',() => that.setHeight(), false);
		},
		beforeDestroy(){
			window.removeEventListener('resize', this.setHeight(), false)
		},
		destroyed(){
		    this.setIntervalTime.cancel();
		    this.setIntervalTime = null;
		}
	}
</script>

<style scoped>
	.Icon span{
		font-size: 16px;
	}
	.Icon img{
		width: 20px !important;
		height: 20px !important;
		vertical-align: sub;
		margin-right: 5px;
	}
	.works{
		font-weight: bold;
		margin-top:10px;
	}
	.text{
		text-indent: 2em;
		margin-top:10px;
		line-height: 20px;
	}
	.author{
		font-weight:bold;
		margin-top:15px;
	}
	.author font{
		color:#409EFF;
	}
	.submit{
		padding:25px;
		text-align: right;
		font-size: 18px;
		font-weight: bold;
		background:#fff;
	}
	.submit .el-button{
		background:rgb(26, 131, 252);
		color:#fff;
		border-color:rgb(26, 131, 252);
		vertical-align: baseline;
	}
	.submit font{
		font-size: 30px;
		vertical-align: sub;
	}
	.totalAmount{
		font-size:20px;
		font-weight:bold;
		padding:25px;
	}
	.totalAmount div:last-child{
		margin-top:10px;
	}
	.paymentBox{
		padding: 25px;
		box-sizing: border-box;
	}
	.payment{
		border-top: 1px solid #999;
		background: #fff;
		padding: 20px;
		box-sizing: border-box;
		font-weight: bold;
	}
	.momeyBox{
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
	}
	.payPass{
		margin-top:15px;
	}
	.text{
		text-align: right;
		font-size:14px;
		margin-top:5px;
		color:rgba(153, 153, 153, 1);
	}
	.wallet{
		height:30px;
	}
	.momey{
		width:258px;
		background-color: rgb(236, 245, 252);
		padding:15px;
	}
	.AddressBox{
		padding: 25px;
		box-sizing: border-box;
	}
	.Address{
		border-top: 1px solid #999;
		background: #fff;
		padding: 30px;
		overflow: hidden;
		box-sizing: border-box;
		font-size: 18px;
		color: rgb(129, 129, 129);
		font-weight:bold;
	}
	.Order{
		width:1280px;
		height:auto;
		margin:20px auto;
		background:rgb(248, 248, 248);
		border:1px solid rgb(187, 187, 187);
	}
	.btn .el-button{
		width:150px;
		height:45px;
		background:rgb(49, 0, 75);
		border-color:rgb(49, 0, 75);
		color:#fff;
		border-radius: 0px;
	}
	.name{
		font-weight: 700;
		font-size: 20px;
		color: rgb(16, 16, 16);
		margin-top:25px;
		margin-left:25px;
	}
	.content{
		padding:25px;
		box-sizing: border-box;
	}
	.content .box{
		height:350px;
		border-top:1px solid #999;
		background:#fff;
		padding:20px;
		overflow: hidden;
		box-sizing: border-box;
	}
	.boxLeft{
		width:225px;
		height:310px;
		float: left;
		position: relative;
	}
	.play{
	    width: 50px;
	    height: 50px;
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    margin-left: -25px;
	    margin-top: -25px;
	}
	.boxRight{
		width:940px;
		height:310px;
		margin-left:25px;
		float: right;
	}
	.boxLeft img{
		object-fit:cover;
	}
	.boxRight .title{
		font-weight: 700;
		font-size: 28px;
		color: rgb(16, 16, 16);
	}
	.boxRight .shiwu{
		width:24px;
		height:24px;
		vertical-align: middle;
	}
	.boxRight .Id{
		font-weight: bold;
		font-size: 16px;
		color: rgb(16, 16, 16);
		margin-top:5px;
	}
	.boxRight .album{
		font-size: 18px;
		color: rgb(45, 129, 226);
		font-weight: bold;
		margin-top:5px;
	}
	.money{
		font-size: 28px;
		color: rgb(255, 0, 0);
		font-weight: bold;
		margin-top:5px;
		display: flex;
		justify-content: space-between;
	}
	.money span{
		font-size:20px;
	}
	.money .time{
		font-size:16px;
	}
	.author{
		margin-top:5px;
	}
	.author span{
		height:40px;
		line-height: 40px;
		display: inline-block;
		font-size:16px;
		font-weight: bold;
	}
	.author img{
		width:40px;
		height:40px;
		border-radius: 50%;
		vertical-align: top;
	}
	.nature{
		height:auto;
		overflow: hidden;
		margin-top:15px;
	}
	.nature ul li{
		width: 180px;
		height: 60px;
		text-align: center;
		vertical-align:middle;
		background: rgb(237, 248, 253);
		border:1px solid rgb(60, 178, 229);
		font-size:14px;
		font-weight:bold;
		float: left;
		margin-right:10px;
		margin-bottom:10px;
		padding-top:10px;
		box-sizing: border-box;
	}
	.nature ul li font{
		color:rgba(60, 178, 229, 1);
	}
</style>
