<template>
	<div class="addressList">
		<div class="addressBox">
			<div class="box" v-for="(item,index) in rows" :key="item.asId">
				<div class="boxLeft">
					<el-radio v-model="asId" :label="index" size="large"> </el-radio>
				</div>
				<div class="boxRight">
					<div>{{item.asPerson}}&nbsp;&nbsp;{{item.asMobile}}<span v-if="item.asDefault=='1'">默认</span></div>
					<div>{{item.peName}}&nbsp;{{item.cyName}}&nbsp;{{item.dtName}}&nbsp;{{item.asAddress}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				rows:[],
				asId: 0
			}
		},
		created() {
			this.initData();
		},
		methods: {
			uploadAddress(){
				let ruleForm = this.rows[this.asId];
				ruleForm.isAsMobile = ruleForm.asMobile.substring(0,7) +"****";
				this.$emit("closeAddress",ruleForm);
			},
			initData() {
				let that = this;
				that.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url: 'user/as/getList',
					data:that.$qs.stringify({
						urId: window.localStorage.getItem("urId"),
						page: 1,
						limit: 30
					})
				})
				.then((res)=>{
					that.rows = res.data.dataRoot;
					that.rows.forEach((item,index)=>{
						let asDefault = item.asDefault;
						if(asDefault == "1"){
							that.asId = index;
						}
					})
				})
				.catch((err)=>console.log('请求失败',err))
			}
		},
	}
</script>

<style scoped>
	.box{
		display: flex;
		padding:10px;
		margin-top:15px;
		background:#f3f3f3;
		border-radius: 10px;
	}
	.boxLeft{
		width:25px;
	}
	.boxRight{
		flex: 1;
	}
	.boxRight  div:last-child{
		margin-top: 5px;
	}
	.boxRight span{
		padding:2px 5px;
		background:red;
		color:#fff;
		border-radius: 5px;
		font-size:12px;
		margin-left:5px;
	}
</style>
