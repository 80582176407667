<template>
	<div class="from">
		<el-form
			label-position="top" 
			label-width="100px"
			ref="ruleForm"
			:model="ruleForm"
			:rules="rules"
		>
			<el-form-item label="收件人" prop="asPerson">
				<el-input v-model="ruleForm.asPerson" placeholder="请填写收件人"/>
			</el-form-item>
			<el-form-item label="收件人电话" prop="asMobile">
				<el-input v-model="ruleForm.asMobile" placeholder="请填写收件人电话"/>
			</el-form-item>
			<el-form-item label="所在省份" prop="peId">
				<el-select v-model="ruleForm.peName" filterable  placeholder="请选择" style="width:100%" @change="selectPeIdBtn">
				    <el-option
				      v-for="item in peIdRow"
				      :key="item.peId"
				      :label="item.peName"
				      :value="item"
				    />
				</el-select>
			</el-form-item>
			<el-form-item label="所在市区" prop="cyId">
				<el-select v-model="ruleForm.cyName" filterable  placeholder="请选择" style="width:100%" @change="selectCyIdBtn">
				    <el-option
				      v-for="item in cyIdRow"
				      :key="item.cyId"
				      :label="item.cyName"
				      :value="item"
				    />
				</el-select>
			</el-form-item>
			<el-form-item label="所在区县" prop="dtId">
				<el-select v-model="ruleForm.dtName" filterable  placeholder="请选择" style="width:100%" @change="selectDtIdBtn">
				    <el-option
				      v-for="item in dtIdRow"
				      :key="item.dtId"
				      :label="item.dtName"
				      :value="item"
				    />
				</el-select>
			</el-form-item>
			<el-form-item label="详细地址" prop="asAddress">
				<el-input v-model="ruleForm.asAddress" placeholder="请输入详细地址"/>
			</el-form-item>
			<el-form-item label="默认地址" prop="asDefault">
				<el-select v-model="ruleForm.asDefault" placeholder="请选择" style="width:100%">
				    <el-option key="1" label="是" value="1"/>
					<el-option key="0" label="否" value="0"/>
				</el-select>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	export default {
		data(){
			const validateMobile = (rule,value, callback) => {
				if(!(/^1[345789]\d{9}$/.test(value))){ 
					callback(new Error('请输入正确的手机号码'))
				} else {
					callback()
				}
			}
			return{
				peIdRow:[],
				cyIdRow:[],
				dtIdRow:[],
				ruleForm:{
					asId:'',
					asPerson:'',
					asMobile:'',
					peId:'',
					peName:'',
					cyId:'',
					cyName:'',
					dtId:'',
					dtName:'',
					asAddress:'',
					asDefault:'1',
					urId:window.localStorage.getItem("urId")
				},
				rules: {
					asPerson: [
						{ required: true, message: '收件人不能为空', trigger: 'change' }
					],
					asMobile: [
						{ required: true, validator: validateMobile, trigger: 'change' }
					],
					peId:[
						{ required: true, message: '所在省份不能为空', trigger: 'change' }
					],
					cyId:[
						{ required: true, message: '所在市区不能为空', trigger: 'change' }
					],
					dtId:[
						{ required: true, message: '所在区县不能为空', trigger: 'change' }
					],
					asAddress:[
						{ required: true, message: '详细地址不能为空', trigger: 'change' }
					]
				},
			}
		},
		created(){
			this.getListProvince()
		},
		methods:{
			submitForm(formName){
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						let url = "user/as/save";
						if(that.ruleForm.asId != ''){
							url = "user/as/update";
						}
						this.$axios({
							method:'post',
							url:url,
							headers: {
								token: window.localStorage.getItem("token")
							},
							data:this.$qs.stringify(that.ruleForm)
						})
						.then((res)=>{
							let msg = res.data.msg;
							if(msg==2){
								that.$message.success("保存成功!");
								that.$emit("onClose",'0',that.ruleForm);
							}else{
								that.$message.error(msg);
								that.$emit("onClose",'1');
							}
						})
						.catch((err)=>console.log('请求失败',err))
					} else {
						return false;
					}
				});
			},
			selectPeIdBtn(val){
				this.ruleForm.peId = val.peId;
				this.ruleForm.peName = val.peName;
				this.ruleForm.cyId = "";
				this.ruleForm.cyName = "";
				this.ruleForm.dtId = "";
				this.ruleForm.dtName = "";
				this.getListCity(val.peId);
			},
			selectCyIdBtn(val){
				this.ruleForm.cyId = val.cyId;
				this.ruleForm.cyName = val.cyName;
				this.ruleForm.dtId = "";
				this.ruleForm.dtName = "";
				this.getListCounty(val.cyId);
			},
			selectDtIdBtn(val){
				this.ruleForm.dtId = val.dtId;
				this.ruleForm.dtName = val.dtName;
			},
			initData(data){
				let that = this;
				if(data != ''){
					that.$axios({
						method:'post',
						headers: {
							token: window.localStorage.getItem("token")
						},
						url: 'user/as/getObject',
						data:that.$qs.stringify({
							asId: data.asId
						})
					})
					.then((res)=>{
						that.ruleForm = res.data.data;
						that.getListCity(res.data.data.peId);
						that.getListCounty(res.data.data.cyId);
					})
					.catch((err)=>console.log('请求失败',err))
				}
			},
			getListProvince(){
				let that = this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'sys/pe/getAll',
					data:this.$qs.stringify({
						page: 1,
						limit: 100000
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.peIdRow = rows;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListCity(peId){
				let that = this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'sys/cy/getList',
					data:this.$qs.stringify({
						peId: peId,
						page: 1,
						limit: 100000
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.cyIdRow = rows;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getListCounty(cyId){
				let that = this;
				this.$axios({
					method:'post',
					headers: {
						token: window.localStorage.getItem("token")
					},
					url:'sys/dt/getList',
					data:this.$qs.stringify({
						cyId: cyId,
						page: 1,
						limit: 100000
					})
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.dtIdRow = rows;
				})
				.catch((err)=>console.log('请求失败',err))
			},
		}
	}
</script>
<style scoped>
	
</style>
